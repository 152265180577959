import * as React from "react";
import {Link} from "gatsby"
import "./Sidebar.css"


const Sidebar = ({ image, title, timestamp,slug }) => {
return (
  <Link className="sidebarLink" to={`/Perspective/${slug}`}>  
 <div className="sidebar">
         <img  src={image} alt=""/>
      
    
    <div className="sidebarDetails">
     <p>{title}</p>
     <div className="sidebar_footer">
         <div className="sidebar_timestamp"> 
     <p>{timestamp} </p> 
     </div>
     <h2 >&nbsp;&nbsp;&rarr;</h2>
     </div>
   
    </div>
 

   </div>
  
 </Link>

);

}

export default Sidebar


